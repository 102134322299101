/**
 * GraphQL Query that fetches products using received search term and the params
 * for filter, sort and pagination.
 */
export default `
  query getCatalogSearchFacetData($search: String = "", $filter: ProductAttributeFilterInput, $pageSize: Int = 10, $currentPage: Int = 1, $sort: ProductAttributeSortInput) {
    catalogSearchProducts(search: $search, filter: $filter, pageSize: $pageSize, currentPage: $currentPage, sort: $sort) {
      items {
        __typename
        uid
        type_id
        name
        sku
        stock_status
        width
        height
        depth
        length
        diameter
        crossed_out_list_price
        product_brand_label
        product_category
        calculated_lead_time
        rating_summary
        total_qty_sold
        thumbnail {
          url
        }
        secondary_image {
          url
        }
        url_key
        url_rewrites {
          url
        }
        price_range {
          maximum_price {
            final_price {
              currency
              value
            }
            regular_price {
              currency
              value
            }
          }
          minimum_price {
            final_price {
              currency
              value
            }
            regular_price {
              currency
              value
            }
          }
        }
        mp_label_data {
          name
          rule_id
          customer_group_ids
          label
          label_color
          label_css
          label_font
          label_image
          label_position
          label_template
        }
        configurable_options {
            attribute_code
            attribute_id
            attribute_uid
            label
            position
            uid
            use_default
            values {
              label
              uid
            }
        }
        category_custom_swatch {
          swatch_data {
            uid
            swatch_value
            variation_base_image_position
            product_image
          }
        }
      }
      page_info {
        current_page
        page_size
        total_pages
      }
      total_count
    }
  }
`;
